import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import withContext from 'store/withContext'
import { Helmet } from 'react-helmet'

import NewsFeatured from 'components/Home/NewsFeatured'
import NewsItem from 'components/Home/NewsItem'
import NewsLoader from 'components/Global/NewsLoader'
import MatchScheduleCard from 'components/Global/MatchScheduleCard'
import VerticalPromoCard from 'components/Home/VerticalPromoCard'
import SocialCard from 'components/Global/SocialCard'
import { SlimStandingsCard } from 'components/Global/StandingsCard'
import { getCookieYesCookie } from 'helpers/helperFunctions'

import { formatDistanceToNow } from 'date-fns'

import PageWrapper from '../styles/index.css'

const { NODE_ENV } = process.env
const showTwitch = NODE_ENV !== 'development'

const Home = props => {
  const [hideTwitch, setHideTwitch] = useState(false)
  const [twitchAllowed, setTwitchAllowed] = useState(false)

  const { newsCarousel, newsList } = props.context

  useEffect(() => {
    const cookie = getCookieYesCookie()
    if (cookie.functional === 'yes') {
      setTwitchAllowed(true)
    }
  }, [])

  return (
    <PageWrapper className="home-page">
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="a20n32doitimo2q12c6kaaefxfu6xm"
        />
      </Helmet>
      <div className="center-wrapper">
        <div className="content">
          <NewsFeatured data={newsCarousel && newsCarousel} />
          <div className="news-wrapper">
            {newsList &&
              newsList.map(item => (
                <NewsItem
                  date={formatDistanceToNow(new Date(item.date.split(' ')[0]), {
                    addSuffix: true,
                  })}
                  excerpt={item.excerpt}
                  thumbnail={
                    item.format === 'video'
                      ? `https://img.youtube.com/vi/${item.video_id}/hqdefault.jpg`
                      : item.header_image
                  }
                  title={item.title}
                  type={item.format}
                  video_id={item.video_id && item.video_id}
                  slug={item.slug}
                  key={item.title}
                />
              ))}
            {!newsList && <NewsLoader type="list" />}
          </div>
        </div>
        <div className="sidebar">
          {showTwitch && twitchAllowed && (
            <div className={`twitch-embed ${hideTwitch}`}>
              <iframe
                title="twitch"
                src="https://player.twitch.tv/?channel=smitegame&parent=www.smiteesports.com"
                muted={true}
                frameBorder="0"
                allowFullScreen={true}
                scrolling="no"
                height="378"
                width="620"
              ></iframe>
              <div className="close" onClick={() => setHideTwitch(true)}>
                X
              </div>
            </div>
          )}
          {props.context.standings2 ? (
            <SlimStandingsCard
              season={process.env.GATSBY_LEAGUE_HEADER}
              data={props.context.standings !== null && props.context.standings}
              header={
                process.env.GATSBY_LEAGUE === 'SCC' ? 'SCC NA Phase 1' : ''
              }
            />
          ) : (
            ''
          )}
          {props.context.standings2 ? (
            <SlimStandingsCard
              season={process.env.GATSBY_LEAGUE_HEADER}
              data={
                props.context.standings2 !== null && props.context.standings2
              }
              header="SCC EU Phase 1"
            />
          ) : (
            ''
          )}
          {props.context.ad !== null && (
            <VerticalPromoCard
              desktop_image="https://webcdn.hirezstudios.com/smite-esports/wp-content/uploads/2024/06/awe2-530x238-2.png" //{this.props.context.ad.promo_image}
              mobile_image="https://webcdn.hirezstudios.com/smite-esports/wp-content/uploads/2024/06/awe2-530x238-2.png" //{this.props.context.ad.promo_image}
              url="https://discord.com/invite/smiteesports" //{this.props.context.ad.promo_url}
            />
          )}
          <SocialCard
            heading={`Follow SMITE Esports`}
            copy="Stay connected with the latest news and scores"
            links={[
              {
                service: 'facebook',
                url: 'https://www.facebook.com/officialsmitepro',
              },
              {
                service: 'instagram',
                url: 'https://www.instagram.com/smiteproleague',
              },
              {
                service: 'x',
                url: 'https://twitter.com/SmitePro',
              },
              {
                service: 'youtube',
                url: 'https://www.youtube.com/Smitepro',
              },
              {
                service: 'twitch',
                url: 'https://www.twitch.tv/SmiteGame',
              },
              {
                service: 'discord',
                url: 'https://discord.gg/smiteesports',
              },
            ]}
          />
        </div>
      </div>
    </PageWrapper>
  )
}

export default withContext(Home)
