export function getCookieYesCookie() {
  const cookies = document.cookie.split(';')
  const cookieYesCookie = cookies.find(cookie =>
    cookie.trim().startsWith('cookieyes-consent=')
  )

  if (!cookieYesCookie) return null

  const cookieValue = cookieYesCookie.split('=')[1]
  const cookiePairs = cookieValue.split(',')

  const cookieObject = {}
  cookiePairs.forEach(pair => {
    const [key, value] = pair.split(':')
    cookieObject[key] = value
  })

  return cookieObject
}
